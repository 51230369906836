import React from "react"
import PropTypes from "prop-types"

import "../styles/concept-section-index.scss"

const ConceptSectionIndex = ({ concepts, title }) => (
    <section className="concept-section-index ">
        <div className="section-title">{title}</div>
        <div className="line"></div>
        <div className="columns">
            {concepts.map((concept, idx) => (
                <div className="column has-text-centered" key={idx}>
                    <div className="top">{concept.top}</div>
                    <div className="content">{concept.content}</div>
                    <div className="bottom">{concept.bottom}</div>
                </div>
            ))}
        </div>
    </section>
)

ConceptSectionIndex.propTypes = {
    concepts: PropTypes.array,
    title: PropTypes.node,
}

ConceptSectionIndex.defaultProps = {
    concepts: [],
    title: null,
}

export default ConceptSectionIndex
