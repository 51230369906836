import React from "react"
import PropTypes from "prop-types"

import TECLogo from "../assets/allies/tec-de-monterrey@2x.png"
import GobLogo from "../assets/allies/gobierno-zapopan@2x.png"
import LuciernagaLogo from "../assets/allies/luciernaga@2x.png"
import ZapopanLogo from "../assets/allies/reto-zapopan@2x.png"
import SICYTLogo from "../assets/allies/sicyt@2x.png"
import ProsociedadLogo from "../assets/allies/prosociedad@2x.png"
import FondifyLogo from "../assets/allies/fondify@2x.png"
import ImaginationLogo from "../assets/allies/imagination-org@2x.png"
import FonnorLogo from "../assets/allies/fonnor@2x.png"
import TalenyLogo from "../assets/allies/taleny@2x.png"

import "../styles/grid-logo.scss"

const GridLogo = ({ hideTitle }) => (
  <section className="grid-logo">
    {hideTitle ? null : (
      <h2 className="section-title has-text-centered">
        ¿<span className="s-italic">Quiénes</span>{" "}
        <span className="c-turquoise w-bold">juegan</span> con{" "}
        <span className="w-bold">nosotros</span>?
      </h2>
    )}
    <div className="logos-section">
      <div className="columns">
        <div className="column has-text-centered">
          <img src={TECLogo} alt="Logo ITESM" />
        </div>
        <div className="column has-text-centered">
          <img src={GobLogo} alt="Logo Gobierno Zapopan" />
        </div>
        <div className="column has-text-centered">
          <img src={LuciernagaLogo} alt="Logo Luciernaga" />
        </div>
        <div className="column has-text-centered">
          <img src={ZapopanLogo} alt="Logo Zapopan" />
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <img src={SICYTLogo} alt="Logo SICTY" />
        </div>
        <div className="column has-text-centered">
          <img src={ProsociedadLogo} alt="Logo Prosociedad" />
        </div>
        <div className="column has-text-centered">
          <img src={FondifyLogo} alt="Logo Fondify" />
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <img src={ImaginationLogo} alt="Logo Imagination" />
        </div>
        <div className="column has-text-centered">
          <img src={FonnorLogo} alt="Logo Fonnor" />
        </div>
        <div className="column has-text-centered">
          <img src={TalenyLogo} alt="Logo Talenty" />
        </div>
      </div>
    </div>
  </section>
)

GridLogo.propTypes = {
  hideTitle: PropTypes.bool,
}

GridLogo.defaultProps = {
  hideTitle: false,
}

export default GridLogo
