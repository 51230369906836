import React from "react"

import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"

import Button from "../components/button"
import MainBanner from "../components/main-banner"
import ConceptSection from "../components/concept-section"
import ConceptSectionIndex from "../components/concept-section-index"
import GridLogo from "../components/grid-logo"
import CustomInput from "../components/custom-input"
import BiContent from "../components/bi-content"

import DinosaurIcon from "../assets/icons/icons-1x/dinosaur.svg"
import IdeaIcon from "../assets/icons/icons-1x/idea.svg"
import PlanetIcon from "../assets/icons/icons-1x/planet.svg"
import MeiquerLogo from "../assets/logos/logo-meiquer.svg"
import MeiquerLabsLogo from "../assets/logos/logo-meiquer-labs.svg"
import EspiralMeiquerLogo from "../assets/logos/logo-espiral-meiquer.svg"
import ExperienciaMeiquerLogo from "../assets/logos/logo-experiencia-meiquer.svg"
import "../styles/index.scss"
import PlaceholderImage from "../assets/placeholders/placeholder-01@2x.png"

const mainBannerContent = (
  <>
    <div>
      <h1>Inspira</h1>
      <h1>Imagina</h1>
      <h1>Crea</h1>
      <h1 className="c-turquoise">Juega</h1>
      <h1>Comparte</h1>
    </div>
  </>
)

const newsletterBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="c-turquoise w-bold">Comienza</span> tu{" "}
      <span className="w-bold">prueba gratis</span> con{" "}
      <span className="s-italic">Espiral Meiquer</span>
    </h2>
    <br></br>
    <p>¡Suscríbete para recibir una semana gratis de actividades de juego para niñas y niños!</p>
    <form
      action="https://facebook.us18.list-manage.com/subscribe/post?u=aba877962ac636b85ddb41a18&amp;id=ebfa92cb99"
      method="post"
      target="_blank"
    >
      <CustomInput
        type="email"
        label="Correo Electrónico"
        name="EMAIL"
        placeholder="sergio@meiquer.mx"
      />
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button>Suscribirme</Button>
        </div>
      </div>
    </form>
  </>
)

const services = [
  {
    top: (
      <img
        style={{ width: "6em" }}
        src={EspiralMeiquerLogo}
        alt="Espiral Meiquer Logo"
      />
    ),
    content: (
      <p>
        Experiencia de aprendizaje en línea para desarrollar habilidades
        jugando.
      </p>
    ),
    bottom: <Button size="size" linkTo="espiral-meiquer">Probar</Button>,
  },
  {
    top: (
      <img
        style={{ width: "6em" }}
        src={MeiquerLogo}
        alt="Estación Meiquer Logo"
      />
    ),
    content: (
      <img
        style={{ width: "6em" }}
        src={MeiquerLabsLogo}
        alt="Meiquer Labs Logo"
      />

    ),
    bottom:
      <img
        style={{ width: "6em" }}
        src={ExperienciaMeiquerLogo}
        alt="Experiencia Meiquer Logo"
      />,
  },
  {
    top: (
      <p>
        Espacios comunitarios de juego libres y seguros para niñas y niños en
        Zapopan.
      </p>
    ),
    content: (
      <p>
        Diseñamos actividades y espacios de juego creativo para organizaciones y empresas
      </p>
    ),
    bottom:
      <p>
        Experiencias de juego creativo para pascua y verano en espacios seguros
        e innovadores.
  </p>
    ,
  },
  {
    top: (

      <Button disabled size="size">Próximamente</Button>
    ),
    content: (
      <Button size="size" linkTo="/contacto-ventas">Contactar</Button>

    ),
    bottom: <Button size="size" disabled>Próximamente</Button>,
  },
]

const servicesTitle = (
  <h2 className="w-normal">
    ¿Quieres <span className="c-turquoise w-bold">jugar</span> con{" "}
    <span className="w-bold">nosotros</span>?
  </h2>
)

const reasons = [
  {
    top: (
      <img style={{ height: "10rem" }} src={PlanetIcon} alt="Ícono Planeta" />
    ),
    content: (
      <>
        <h3>Explorar</h3>
        <p>
          Jugar nos permite explorar mundos, experimentar en ellos y descubrir
          nuevas cosas
        </p>
      </>
    ),
  },
  {
    top: <img style={{ height: "10rem" }} src={IdeaIcon} alt="Ícono Idea" />,
    content: (
      <>
        <h3>Imaginar</h3>
        <p>
          Los ambientes de juego nos dan la libertad de soñar y planear posibles
          soluciones a través de actividades recreativas
        </p>
      </>
    ),
  },
  {
    top: (
      <img
        style={{ height: "10rem" }}
        src={DinosaurIcon}
        alt="Ícono Dinosaurio"
      />
    ),
    content: (
      <>
        <h3>Conocer</h3>
        <p>
          Jugando nos conocemos a nosotros mismos y conocemos mejor a los demás
        </p>
      </>
    ),
  },
]

const reasonsTitle = (
  <h2 className="w-normal text-right">
    ¿<span className="s-italic">Para qué</span>{" "}
    <span className="w-bold">debemos</span>{" "}
    <span className="c-turquoise w-bold">jugar</span>?
  </h2>
)

//New Content
const mainHeaderTitle = (
  <h1 className="w-normal">
    <span className="w-bold">Experiencias de</span>{" "}
    <span className="c-turquoise w-bold">Juego</span>
  </h1>
)

const mainHeaderContent = (
  <div>

    <p>
      Acercamos el <span className="c-turquoise w-bold">juego</span> creativo a
      la <span className="w-bold">infancia y su comunidad</span>: con cursos en
      las vacaciones, con talleres el fin de semana, con misiones en línea o con
      actividades interactivas.
    </p>
  </div>
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      pascuaImg: file(relativePath: { eq: "meiquer-pascua-05.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      retokidsImg: file(
        relativePath: { eq: "meiquer-retokids-zapopan-03.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      watercolorImg: file(relativePath: { eq: "meiquer-bg-05.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meiquerFonnorImg: file(relativePath: { eq: "meiquer-fonnor-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <MainBanner
        image={data.pascuaImg.childImageSharp.fluid}
        content={mainBannerContent}
      />

      <BiContent
        image={PlaceholderImage}
        content={mainHeaderContent}
        title={mainHeaderTitle}
      />
      <ConceptSection concepts={reasons} title={reasonsTitle} />

      <ConceptSectionIndex concepts={services} title={servicesTitle} />
      <MainBanner
        image={data.watercolorImg.childImageSharp.fluid}
        content={newsletterBannerContent}
        anchor="newsletter"
      />
      <GridLogo />
    </Layout>
  )
}

export default IndexPage
